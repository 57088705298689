import produce from 'immer';

import {
    GAS_STATION_INSTALLMENTS_LIST_ERROR,
    GAS_STATION_INSTALLMENTS_LIST_REQUEST,
    GAS_STATION_INSTALLMENTS_LIST_SUCCESS,
    GAS_STATION_INSTALLMENTS_CREATE_REQUEST,
    GAS_STATION_INSTALLMENTS_EXEMPT_REQUEST
} from "./actions";

const INIT_STATE = {
    list: [],
    currentPage: '',
    totalPages: '',
    totalItems: '',
    skipItems: 0,
    loading: false,
    error: '',
    success: false,
};

export default (state = INIT_STATE, action) => {
    return produce(state, draft => {
        switch (action.type) {
            case GAS_STATION_INSTALLMENTS_LIST_REQUEST:
                draft.loading = true;
                draft.error = '';
                break;
            case GAS_STATION_INSTALLMENTS_LIST_SUCCESS:
                draft.loading = false;
                draft.list = action.payload.list;
                draft.currentPage = action.payload.pages.currentPage;
                draft.totalPages = action.payload.pages.totalPages;
                draft.totalItems = action.payload.items.totalItems;
                draft.skipItems = action.payload.items.skipItems;
                draft.error = '';
                break;
            case GAS_STATION_INSTALLMENTS_LIST_ERROR:
                draft.loading = false;
                draft.list = [];
                draft.error = action.payload.message;
                break;
            case GAS_STATION_INSTALLMENTS_CREATE_REQUEST:
                draft.loading = true;
                draft.error = '';
                break;
            case GAS_STATION_INSTALLMENTS_EXEMPT_REQUEST:
                draft.loading = true;
                draft.error = '';
                break;
            default:
                return state;
        }
    })
}