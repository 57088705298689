import * as auth from './auth';
import * as user from './user';
import * as organizations from './organizations';
import * as report from './report';
import * as hydrate from './hydrate';
import * as transactions from './transactions';
import * as deposits from './deposits';
import * as moneyTransfer from './moneyTransfer';
import * as gasStations from './gasStations';
import * as vehicles from './vehicles';
import * as admins from './admins';
import * as stationUsers from './stationUsers';
import * as permissions from './permissions';
import * as userPermissions from './userPermissions';
import * as discountRate from './discountRate';
import * as bills from './bills';
import * as balanceSharing from './balanceSharing';
import * as gasStationBillings from './gasStationBillings';
import * as fuelManagement from './fuelManagement';
import * as organizationInvoices from './organizationInvoices';
import * as purchase from './purchase';
import * as module from './module';
import * as subcriptions from './subscriptions';
import * as plans from './plans';
import * as menu from './menu';
import * as banks from './banks';
import * as customModules from './customModules';
import * as fraudSuspicions from './fraudSuspicion';
import * as fuelManagementUsers from './fuelManagementUsers';
import * as fuelManagementTransactions from './fuelManagementTransactions';
import * as gasStationList from './gasStationList';
import * as paymentAuthorization from './paymentAuthorization';
import * as gasStationInstallments from './gasStationInstallments';

export default {
  auth,
  user,
  organizations,
  report,
  hydrate,
  transactions,
  deposits,
  moneyTransfer,
  gasStations,
  vehicles,
  admins,
  stationUsers,
  permissions,
  userPermissions,
  discountRate,
  bills,
  balanceSharing,
  gasStationBillings,
  fuelManagement,
  organizationInvoices,
  purchase,
  module,
  subcriptions,
  plans,
  menu,
  banks,
  customModules,
  fraudSuspicions,
  fuelManagementUsers,
  fuelManagementTransactions,
  gasStationList,
  paymentAuthorization,
  gasStationInstallments
};
