import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import * as gasStationInstallments from './actions';
const { APIParams } = require('helpers/Utils');
const {
  listGasStationInstallmentsSuccess,
  listGasStationInstallmentsError,
  listGasStationInstallments,
} = require('./actions');

function* get(api, { payload }) {
  const response = yield call(
    api.list,
    payload.id,
    APIParams({ ...payload.params })
  );
  if (response.ok) {
    const list = response.data.docs;
    const { current: currentPage, total: totalPages } = response.data.pages;
    const { total: totalItems, skip: skipItems = 0 } = response.data.items;
    const pages = { currentPage: Number(currentPage), totalPages };
    const items = { totalItems, skipItems };

    yield put(listGasStationInstallmentsSuccess({ list, pages, items }));
  } else {
    const message = 'Não foi possível carregar estes dados';

    yield put(listGasStationInstallmentsError(message));
  }
}

function* post(api, { payload }) {
  const response = yield call(api.create, payload);

  if (response.ok) {
    yield put(listGasStationInstallments(payload.gasStationId));
  } else {
    const message = 'Não foi possível criar este registro';

    yield put(listGasStationInstallmentsError(message));
  }
}

function* exempt(api, { payload }) {
  console.log({ payload });
  const response = yield call(api.exempt, payload.id);

  if (response.ok) {
    yield put(listGasStationInstallments(payload.gasStationId));
  } else {
    const message = 'Não foi possível isentar este registro';

    yield put(listGasStationInstallmentsError(message));
  }
}

function* watchGet(api) {
  yield takeEvery(
    gasStationInstallments.GAS_STATION_INSTALLMENTS_LIST_REQUEST,
    get,
    api
  );
}

function* watchPost(api) {
  yield takeEvery(
    gasStationInstallments.GAS_STATION_INSTALLMENTS_CREATE_REQUEST,
    post,
    api
  );
}

function* watchExempt(api) {
  yield takeEvery(
    gasStationInstallments.GAS_STATION_INSTALLMENTS_EXEMPT_REQUEST,
    exempt,
    api
  );
}

export default function* rootSaga(api) {
  yield all([
    fork(watchGet, api),
    fork(watchPost, api),
    fork(watchExempt, api),
  ]);
}
