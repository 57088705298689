
export const GAS_STATION_INSTALLMENTS_LIST_REQUEST =
  'GAS_STATION_INSTALLMENTS_LIST_REQUEST';
export const GAS_STATION_INSTALLMENTS_LIST_SUCCESS =
  'GAS_STATION_INSTALLMENTS_LIST_SUCCESS';
export const GAS_STATION_INSTALLMENTS_LIST_ERROR =
  'GAS_STATION_INSTALLMENTS_LIST_ERROR';
export const GAS_STATION_INSTALLMENTS_CREATE_REQUEST =
  'GAS_STATION_INSTALLMENTS_CREATE_REQUEST';
export const GAS_STATION_INSTALLMENTS_EXEMPT_REQUEST = 
  'GAS_STATION_INSTALLMENTS_EXEMPT_REQUEST';

export const listGasStationInstallments = id => ({
  type: GAS_STATION_INSTALLMENTS_LIST_REQUEST,
  payload: { id },
});

export const listGasStationInstallmentsSuccess = payload => ({
  type: GAS_STATION_INSTALLMENTS_LIST_SUCCESS,
  payload,
});

export const listGasStationInstallmentsError = message => ({
  type: GAS_STATION_INSTALLMENTS_LIST_ERROR,
  payload: { message },
});

export const createGasStationInstallment = payload => ({
  type: GAS_STATION_INSTALLMENTS_CREATE_REQUEST,
  payload,
})

export const exemptGasStationInstallment = payload => ({
  type: GAS_STATION_INSTALLMENTS_EXEMPT_REQUEST,
  payload,
})
